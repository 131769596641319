

























































































































































































































































































































































import {
  ChevronLeftIcon,
  SaveIcon,
  CheckSquareIcon,
  PrinterIcon,
  UserIcon,
  CheckIcon,
} from "vue-feather-icons";
import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import VueSelect from "vue-select";
import Vue from "vue";
import { makeStateString } from "@/lib/makeStateString";
import DPBChargeCustomerModal from "../../../components/DPBChargeCustomerModal.vue";
import * as _ from "underscore";
import { getBadgeClassForState } from "@/lib/uiStatesUtil";
import { generatePassword } from "@/lib/generatePassword";
import { formatDateFull } from "@/lib/formatDate";

export default Vue.extend({
  name: "OrderDetails",
  components: {
    VueSelect,
    ChevronLeftIcon,
    CheckSquareIcon,
    PrinterIcon,
    SaveIcon,
    UserIcon,
    DPBChargeCustomerModal,
    CheckIcon,
  },
  data() {
    return {
      getBadgeClassForState,
      makeStateString,
      generatePassword,
      formatDateFull,
      id: this.$route.params.id,
      loading: true as boolean,
      hasAddress: false as boolean,
      error: null,
      initialUserDataFromServer: {} as any,
      existingPhoneNumber: "" as string,
      user: {
        firstName: "" as string,
        lastName: "" as string,
        roles: [],
        isActive: true as boolean,
        hasFreeRide: false as boolean,
        phoneNumber: "" as string,
        email: "" as string,
        address: null as object,
        addressString: "" as string,
        lat: "" as string,
        lng: "" as string,
        birthday: "" as string,
        hasWheelChair: false as boolean,
        needsHelpEnteringCar: false as boolean,
        needsChildrenSeat: false as boolean,
        hasYearCard: false as boolean,
        yearCardNumber: "" as string,
        yearCardType: "" as string,
        hasBahnCard: false as boolean,
        bahnCardNumber: "" as string,
        balance: 0 as number,
        reservedBalance: 0 as number,
        // delete
        id: "" as string,
        referenceCode: "" as string,
        allowsContact: false as boolean,
        createdAt: "" as string,
        updatedAt: "" as string,
        lastLoginAt: "" as string,
        ownBalance: 0,
        addressLevel1: "",
        postalCode: "",
        city: "",
        isPartnerAccount: false as boolean,
        mainAccountId: "" as string,
        appVersion: "" as string,
      },
      canChangePassword: false as boolean,
      userPassword: "" as string,
      userRoles: [
        {
          name: "Admin",
          dbName: "ADMIN",
        },
        {
          name: "Kunde",
          dbName: "CUSTOMER",
        },
        {
          name: "Fahrer",
          dbName: "DRIVER",
        },
        {
          name: "Händler",
          dbName: "VENDOR",
        },
        {
          name: "Operator",
          dbName: "OPERATOR",
        },
      ],
      userPayments: [],
      paymentFields: [
        { key: "createdAt", label: "Datum", sortable: false },
        { key: "amount", label: "Betrag", sortable: false },
        { key: "costCenter", label: "Typ", sortable: false },
        { key: "partnerName", label: "Partner", sortable: false },
        { key: "subject", label: "Info", sortable: false },
        { key: "isEdited", label: "Manuell", sortable: false },
      ],
      yearCardTypes: [],
      perPage: 20,
      currentPage: 1,
    };
  },
  computed: {
    rows() {
      return this.userPayments.length
    }
  },
  methods: {
    preSave() {
      let ready = true;
      if (this.userPassword !== "") {
        ready = false;
        this.$bvModal.show("passwordModal");
      }
      if (this.existingPhoneNumber !== this.user.phoneNumber) {
        ready = false;
        this.$bvModal.show("phoneNumberModal");
      }

      if (ready === true) {
        this.save();
      }
    },
    resetReservedBalance: async function () {
      if (
        confirm(
          `Reserviertes Guthaben von ${(this.user.reservedBalance / 100)
            .toFixed(2)
            .replace(".", ",")} auf 0,00 € zurücksetzen?`
        )
      ) {
        try {
          await fetchApi(
            "v1/users/" + this.id + "/reset-reserved-balance",
            "POST"
          );
          // do sth
          alert("Reserviertes Guthaben zurückgesetzt.");
          this.user.reservedBalance = 0;
        } catch (error) {
          console.error(error);
          alert("Error: " + JSON.stringify(error));
        }
      }
    },
    getUser: async function () {
      const id = this.id;
      await fetchApi("v1/users/" + this.id, "GET")
        .then(async (data) => {
          if (data != null) {
            console.log("DATA", data);
            this.initialUserDataFromServer = data;

            this.user.id = id;
            this.user.allowsContact = data.allowsContact;
            this.user.referenceCode = data.referenceCode;
            this.user.createdAt = data.createdAt;
            this.user.updatedAt = data.updatedAt;
            this.user.lastLoginAt = data.lastLoginAt;

            if (!data.isActive) {
              this.user.isActive = false;
            }
            if (data.hasFreeRide) {
              this.user.hasFreeRide = true;
            }

            this.user.firstName = data.firstName;
            this.user.lastName = data.lastName;
            this.user.email = data.email;
            this.user.phoneNumber = data.phoneNumber;
            this.existingPhoneNumber = data.phoneNumber;

            if (data.address) {
              this.hasAddress = true;
              this.user.addressString =
                data.address.addressLevel1 +
                ", " +
                data.address.postalCode +
                " " +
                data.address.city;
              if (data.address.geocodedLocation) {
                this.user.lat = data.address.geocodedLocation.coordinates[1];
                this.user.lng = data.address.geocodedLocation.coordinates[0];
              }
              this.user.address = data.address;
              this.user.postalCode = data.address.postalCode;
              this.user.city = data.address.city;
              this.user.addressLevel1 = data.address.addressLevel1;

              this.initialUserDataFromServer.addressLevel1 =
                data.address.addressLevel1;
              this.initialUserDataFromServer.postalCode =
                data.address.postalCode;
              this.initialUserDataFromServer.city = data.address.city;
            }
            this.user.balance = data.balance;
            this.user.ownBalance = data.ownBalance;
            this.user.reservedBalance = data.reservedBalance;
            this.user.appVersion = data.appVersion
              ? data.appVersion
              : "Version < 5.0.1";

            data.roles.map((r) => {
              const ur = {
                dbName: r,
                name:
                  r === "ADMIN"
                    ? "Admin"
                    : r === "VENDOR"
                      ? "Händler"
                      : r === "DRIVER"
                        ? "Fahrer"
                        : r === "OPERATOR"
                          ? "Operator"
                          : "Kunde",
              };
              this.user.roles.push(ur);
            });

            /*this.user.birthday = new Date(data.birthdayDate).toLocaleDateString(
              "DE-de",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            );*/
            this.user.birthday = data.birthdayDate;

            this.user.hasYearCard = data.hasYearCard ? data.hasYearCard : false;
            this.user.yearCardNumber = data.yearCardNumber;
            this.user.yearCardType = data.yearCardType;
            this.user.hasBahnCard = data.hasBahnCard ? data.hasBahnCard : false;
            this.user.bahnCardNumber = data.bahnCardNumber;
            this.user.isPartnerAccount = data.isPartnerAccount
              ? data.isPartnerAccount
              : false;
            this.user.mainAccountId = data.mainAccountId;

            this.user.hasWheelChair = data.hasWheelChair
              ? data.hasWheelChair
              : false;
            this.user.needsHelpEnteringCar = data.needsHelpEnteringCar
              ? data.needsHelpEnteringCar
              : false;
            this.user.needsChildrenSeat = data.needsChildrenSeat
              ? data.needsChildrenSeat
              : false;
          } else {
            throw data["message"];
          }
          if (data.hasFreeRide) {
            this.user.hasFreeRide = true;
          }
          this.userHasPassword();
          this.getUserPayments();
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    async getUserPayments() {
      await fetchApi("v1/users/" + this.id + "/payments", "GET")
        .then(async (data) => {
          if (data != null) {
            console.log("USERPAYMENTS", data);
            this.userPayments = data;
          }
        })
        .catch((e) => {
          console.error("FAILED TO LOAD USER PAYMENTS", e);
          this.error = e;
        });
    },
    userHasPassword() {
      const matchRoles = ["DRIVER", "VENDOR", "OPERATOR"];
      this.canChangePassword = matchRoles.some((r) =>
        this.initialUserDataFromServer.roles.includes(r)
      );
    },
    makePassword() {
      this.userPassword = generatePassword();
    },
    onChargeModalSuccess: function (newBalance) {
      console.log("onChargeModalSuccess", newBalance);
      this.user.balance = newBalance;
    },
    getAddressData: function (addressData) {
      console.log("ADDRESS", addressData);
      this.user.addressString =
        addressData.route +
        " " +
        addressData.street_number +
        ", " +
        addressData.postal_code +
        " " +
        addressData.locality;
      this.user.address = addressData;
      this.user.lat = addressData.latitude;
      this.user.lng = addressData.longitude;
      this.user.addressLevel1 = `${addressData.route} ${addressData.street_number || ""
        }`.trim();
      this.user.postalCode = addressData.postal_code;
      this.user.city = addressData.locality;
    },
    makeDateString: function (date) {
      return new Date(date).toLocaleDateString("DE-de", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    getSettings: async function () {
      await fetchApi("v1/utils/settings", "GET")
        .then(async (data) => {
          data
            .filter((d) => d.key === "VRN_TICKET_NAMES")[0]
            .value.split(";")
            .map((e) => this.yearCardTypes.push(e));
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });
    },
    save: async function () {
      const path = `v1/users/${this.id}`;
      const userDataToSend = {
        ...this.user,
        birthdayDate: this.user.birthday,
        roles: [...new Set(this.user.roles.map((r) => r.dbName))],
        password: this.userPassword,
      };
      delete userDataToSend.id;
      delete userDataToSend.referenceCode;
      delete userDataToSend.createdAt;
      delete userDataToSend.updatedAt;
      delete userDataToSend.lastLoginAt;
      delete userDataToSend.ownBalance;
      delete userDataToSend.balance;
      delete userDataToSend.reservedBalance;
      delete userDataToSend.address;
      delete userDataToSend.lat;
      delete userDataToSend.lng;
      delete userDataToSend.birthday;
      delete userDataToSend.addressString;
      delete userDataToSend.appVersion;

      if (userDataToSend.birthdayDate === "") {
        userDataToSend.birthdayDate = null;
      }
      if (userDataToSend.yearCardNumber === "") {
        userDataToSend.yearCardNumber = null;
      }
      if (userDataToSend.yearCardType === "") {
        userDataToSend.yearCardType = null;
      }

      //getChangeSet
      const changeset = _.omit(userDataToSend, (v, k) => {
        if (this.initialUserDataFromServer[k] instanceof Array) {
          return _.isMatch(this.initialUserDataFromServer[k], v);
        }
        return this.initialUserDataFromServer[k] === v;
      });
      fetchApi(path, "PATCH", changeset)
        .then((data) => {
          alert("User erfolgreich gespeichert.");
        })
        .catch((e) => {
          this.error = e;
          alert("Es ist ein Fehler aufgetreten.");
          return;
        });
    },
  },
  async created() {
    await this.getUser();
    await this.getSettings();
  },
});
