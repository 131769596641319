





















































































































































































































































































































import { ChevronLeftIcon, SaveIcon, CheckSquareIcon } from "vue-feather-icons";
import Autocomplete from "@trevoreyre/autocomplete-vue";

import Vue from "vue";
import VueSelect from "vue-select";
import { fetchApi } from "@/lib/fetch";

export default Vue.extend({
  name: "PassengerBooking",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    VueSelect,
    CheckSquareIcon,
    Autocomplete,
  },
  data() {
    return {
      error: "" as string,
      form: {
        customerId: null as string,
        pickupMoDStopId: null as string,
        dropoffMoDStopId: null as string,
        numberOfPassengers: "1" as string,
        selectedBookingDateTime: null as any,
        notes: "" as string,
      },
      selectedPrebookingDay: null as any,
      selectedPrebookingTime: null as any,
      prebooking: false as boolean,
      dayArray: [] as any[],
      timeArray: [] as any[],
      selectedUser: null as any,
      selectedStartMoDStop: {} as any,
      selectedEndMoDStop: {} as any,
      estimatedPrice: null as any,
    };
  },
  watch: {
    "form.numberOfPassengers": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onSubmit();
      }
    },
  },
  methods: {
    searchUser: async function (input) {
      if (input.length < 1) {
        return [];
      }
      return fetchApi(
        `v1/users/autocomplete/?limit=100&q=` + input,
        "GET",
        null
      );
    },
    getResultValueUser(result) {
      return result.lastName + ", " + result.firstName;
    },
    submitUser(result) {
      this.form.customerId = result.id;
      fetchApi(`v1/users/${result.id}`, "GET", null).then((user) => {
        this.selectedUser = user;
      });
    },
    searchMoDStop: async function (input) {
      if (input.length < 1) {
        return [];
      }
      return fetchApi(`v1/stops/autocomplete/?q=` + input, "GET", null);
    },
    getResultValueMoDStop(result) {
      return result.name;
    },
    submitDropoff(result) {
      this.form.dropoffMoDStopId = result.id;
      this.selectedEndMoDStop = result;
      this.onSubmit();
    },
    submitPickup(result) {
      this.form.pickupMoDStopId = result.id;
      this.selectedStartMoDStop = result;
      this.onSubmit();
    },
    onSubmit() {
      if (
        this.form.dropoffMoDStopId &&
        this.form.pickupMoDStopId &&
        this.form.numberOfPassengers
      ) {
        fetchApi(`v1/passenger-rides/price-estimate`, "POST", {
          dropoffMoDStopId: this.form.dropoffMoDStopId,
          pickupMoDStopId: this.form.pickupMoDStopId,
          numberOfPassengers: parseInt(this.form.numberOfPassengers),
          userId: this.selectedUser.userId
        }).then((res) => {
          this.estimatedPrice = res;
        });
      }
    },

    makeDayTimeArray: function () {
      const date = new Date();
      const newDay = new Date(date);
      for (let i = 0; i < 31; i++) {
        let day = 0;
        if (i === 0) {
          day = newDay.setDate(date.getDate());
        } else {
          day = newDay.setDate(newDay.getDate() + 1);
        }
        this.dayArray.push({
          value: day,
          label: new Date(day).toLocaleString("de-DE", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        });
      }
      for (let j = 0; j < 24; j++) {
        for (let i = 0; i < 6; i++) {
          // Format 0 to 00
          if (i == 0) {
            this.timeArray.push({ value: [j, 0], label: j + ".00 Uhr" });
          } else {
            this.timeArray.push({
              value: [j, i * 10],
              label: j + "." + i * 10 + " Uhr",
            });
          }
        }
      }
      this.selectedPrebookingDay = this.dayArray[0];
      this.selectedPrebookingTime = this.timeArray[0];
    },
    isToday: (someDate) => {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    },
    setRideTime: function () {
      // Calculate Date by selected Day
      console.log(
        this.selectedPrebookingDay.value,
        this.selectedPrebookingTime.value
      );

      const d = new Date(this.selectedPrebookingDay.value);

      const at =
        this.selectedPrebookingTime.value !== null &&
        d.setHours(
          this.selectedPrebookingTime.value[0],
          this.selectedPrebookingTime.value[1],
          0
        );

      this.form.selectedBookingDateTime = new Date(at).toISOString();
    },
    bookRide: async function (event) {
      event.preventDefault();
      if (this.prebooking) {
        this.setRideTime();
      }
      const body = {
        ...this.form,
        numberOfPassengers: parseInt(this.form.numberOfPassengers),
      };
      console.log(body);

      const res = await fetchApi("v1/passenger-rides", "POST", body).catch(
        (e) => {
          console.error("ERR", e);
          this.error = e;
        }
      );

      if (res) {
        alert("done");
      }
    },
  },
  async created() {
    this.makeDayTimeArray();
    this.selectedPrebookingDay = this.dayArray[0];
  },
});
