<template>
  <div>
    <!-- #########################
                                         ##### Sidebar Admin #####
                                         ######################### -->

    <b-collapse
      id="sidebarMoDAdminWeb"
      class="d-none d-md-block"
      v-if="
        $store.state.role === 'ADMIN' || $store.state.roles.includes('OPERATOR')
      "
    >
      <nav
        v-b-toggle.sidebarCollapse
        class="col-md-3 col-lg-2 d-md-block bg-light sidebar"
        id="sidebarMenu2"
      >
        <div class="position-sticky pt-3" style="margin-left: 15px">
          <b-button
            right
            class="mainRideButtons"
            v-if="
              $store.state.role === 'ADMIN' ||
              $store.state.roles.includes('OPERATOR')
            "
            href=""
            @click="navigator('PassengerBooking')"
            variant="primary"
            style="margin-top: 8px !important"
          >
            <PlusCircleIcon />
            Personenfahrt buchen
          </b-button>
          <br /><br />
          <b-button
            right
            class="mainRideButtons"
            href=""
            @click="navigator('LogisticsBooking')"
            variant="primary"
          >
            <PlusCircleIcon />
            Lieferfahrt buchen
          </b-button>
          <br /><br />
          <h5>Mobility-on-Demand</h5>
          <h6
            class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
          >
            Fahrten
          </h6>
          <ul class="nav flex-column">
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('LogisticsBookingsOverview')"
              >
                <TruckIcon />
                <span> Logistik-Fahrten</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('PassengersBookingsOverview')"
              >
                <UsersIcon />
                <span> Personen-Fahrten</span>
              </b-link>
            </li>
          </ul>

          <h6
            class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
          >
            Administration
          </h6>
          <ul class="nav flex-column">
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('Users')"
              >
                <UsersIcon />
                <span> Nutzer</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('VouchersOverview')"
              >
                <GiftIcon />
                <span> Gutscheine</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('ServiceHours')"
              >
                <ClockIcon />
                <span> Servicezeiten</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('MoDstops')"
              >
                <MapPinIcon />
                <span> MoDstops</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('TransactionsOverview')"
              >
                <CreditCardIcon />
                <span> Transaktionen</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('Vehicles')"
              >
                <TruckIcon />
                <span> Fahrzeuge</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('SettingsMoD')"
              >
                <SettingsIcon />
                <span> Einstellungen</span>
              </b-link>
            </li>
          </ul>
          <!-- ##########################
                    ##### Die Pfalz bringts #####
                    ########################## -->
          <br />
          <h5>Die Pfalz bringts</h5>
          <!-- Nav Items Section Headline-->
          <h6
            class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
          >
            Online-Shop
          </h6>
          <!-- Nav Items Section-->
          <ul class="nav flex-column">
            <li class="nav-item" v-if="false">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('Home')"
              >
                <GridIcon />
                <span> Dashboard</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                href=""
                @click="navigator('Orders')"
              >
                <CalendarIcon />
                <span> Bestellungen</span>
              </b-link>
            </li>
            <li class="nav-item" v-if="!isOperator">
              <b-link
                class="nav-link"
                aria-current="page"
                href=""
                @click="navigator('AdvertisementsOverview')"
              >
                <CalendarIcon />
                <span> Anzeigen</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                href=""
                @click="navigator('WarehousingOverview')"
              >
                <CalendarIcon />
                <span> Warenwirtschaft</span>
              </b-link>
            </li>
          </ul>

          <!-- Nav Items Section Headline-->
          <h6
            class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
          >
            Rubriken
          </h6>
          <!-- Nav Items Section-->
          <ul class="nav flex-column">
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('VendorOverview')"
              >
                <ShoppingBagIcon />
                <span> Lieferanten</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('ProductOverview')"
              >
                <PackageIcon />
                <span> Produkte</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('CategoryOverview')"
              >
                <FolderIcon />
                <span> Kategorien</span>
              </b-link>
            </li>
          </ul>

          <!-- Nav Items Section Headline-->
          <h6
            class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
          >
            Administration
          </h6>
          <!-- Nav Items Section-->
          <ul class="nav flex-column">
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('Storefront')"
              >
                <SmartphoneIcon />
                <span> Storefront</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('SettingsDPB')"
              >
                <SettingsIcon />
                <span> Einstellungen</span>
              </b-link>
            </li>
          </ul>
        </div>
      </nav>
    </b-collapse>
    <!-- ##########################
                        ##### Sidebar Vendor #####
                        ########################## -->

    <b-collapse
      id="sidebarMoDVendorWeb"
      class="d-none d-md-block"
      v-if="$store.state.role === 'VENDOR'"
    >
      <nav
        v-b-toggle.sidebarCollapse
        class="col-md-3 col-lg-2 d-md-block bg-light sidebar"
        id="sidebarMenuVendor"
      >
        <div class="position-sticky pt-3" style="margin-left: 15px">
          <!-- Nav Items Section Headline-->
          <b-button
            right
            href=""
            @click="navigator('LogisticsBooking')"
            variant="primary"
          >
            <PlusCircleIcon />
            Lieferfahrt buchen
          </b-button>
          <h6
            class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
          >
            Online-Shop
          </h6>
          <!-- Nav Items Section-->
          <ul class="nav flex-column">
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                href=""
                @click="navigator('Orders')"
              >
                <CalendarIcon />
                <span> Bestellungen</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                href=""
                @click="navigator('LogisticsBookingsOverview')"
              >
                <TruckIcon />
                <span> Fahrten</span>
              </b-link>
            </li>
          </ul>
          <h6
            class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
          >
            Administration
          </h6>
          <ul class="nav flex-column">
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                href=""
                @click="navigator('VendorDetails', $store.state.roleId)"
              >
                <SettingsIcon />
                <span> Unternehmen</span>
              </b-link>
            </li>
            <li class="nav-item">
              <b-link
                class="nav-link"
                aria-current="page"
                @click="navigator('VendorProducts', $store.state.roleId)"
              >
                <PackageIcon />
                <span> Produkte</span>
              </b-link>
            </li>
          </ul>
        </div>
      </nav>
    </b-collapse>
  </div>
</template>

<script>
import router from "@/router/index";
import {
  UsersIcon,
  SettingsIcon,
  LogOutIcon,
  MapPinIcon,
  ClockIcon,
  EditIcon,
  ToolIcon,
  PackageIcon,
  TruckIcon,
  FolderIcon,
  ShoppingBagIcon,
  GridIcon,
  SmartphoneIcon,
  CalendarIcon,
  GiftIcon,
  PlusCircleIcon,
  CreditCardIcon,
} from "vue-feather-icons";

export default {
  components: {
    UsersIcon,
    SettingsIcon,
    LogOutIcon,
    MapPinIcon,
    ClockIcon,
    EditIcon,
    ToolIcon,
    PackageIcon,
    TruckIcon,
    FolderIcon,
    ShoppingBagIcon,
    GridIcon,
    SmartphoneIcon,
    CalendarIcon,
    GiftIcon,
    PlusCircleIcon,
    CreditCardIcon,
  },
  methods: {
    navigator: function (page, id) {
      router.push({ name: page, params: { id: id } });
    },
  },
  async created() {
    this.isOperator = this.$store.state.roles.includes("OPERATOR");
  },
};
</script>
