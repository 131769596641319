import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import VendorOverview from '../views/DiePfalzBringts/Vendors/VendorOverview.vue'
import VendorDetails from '../views/DiePfalzBringts/Vendors/VendorDetails.vue'
import VendorProducts from '../views/Vendor/VendorProducts.vue'
import ProductOverview from '../views/DiePfalzBringts/Products/ProductOverview.vue'
import ProductDetails from '../views/DiePfalzBringts/Products/ProductDetails.vue'
import ProductAdd from '../views/DiePfalzBringts/Products/ProductAdd.vue'
import VendorAdd from '../views/DiePfalzBringts/Vendors/VendorAdd.vue'
import CategoryOverview from '../views/DiePfalzBringts/Categories/CategoryOverview.vue'
import CategoryDetails from '../views/DiePfalzBringts/Categories/CategoryDetails.vue'
import CategoryAdd from '../views/DiePfalzBringts/Categories/CategoryAdd.vue'
import Orders from '../views/Vendor/Orders/Orders.vue'
import OrderDetails from '../views/Vendor/Orders/OrderDetails.vue'
import LogisticsBooking from '@/views/Vendor/LogisticsBooking.vue'
import UserAdd from '@/views/MobilityOnDemand/Users/UserAdd.vue'
import LogisticsBookingsOverview from "@/views/Vendor/LogisticsBookingsOverview.vue"
import Storefront from "@/views/DiePfalzBringts/Storefront.vue";
import { isAccessTokenValid, accessTokenPayload } from '@/lib/validAccessToken'
import store from "@/store";
import RideDetails from "@/views/MobilityOnDemand/RideDetails.vue";
import Users from "@/views/MobilityOnDemand/Users/Users.vue";
import ProductList from "@/views/DiePfalzBringts/Products/ProductList.vue";
import PassengerBooking from "@/views/MobilityOnDemand/PassengerBooking.vue";
import UserDetails from "@/views/MobilityOnDemand/Users/UserDetails.vue";
import SettingsDPB from "@/views/DiePfalzBringts/SettingsDPB.vue";
import SettingsMoD from "@/views/MobilityOnDemand/SettingsMoD.vue";
import MoDstops from "@/views/MobilityOnDemand/MoDstops.vue";
import MoDstopDetails from "@/views/MobilityOnDemand/MoDstopDetails.vue";
import Scanning from "@/views/MobilityOnDemand/Scanning.vue";
import ServiceHours from "@/views/MobilityOnDemand/ServiceHours.vue";
import NotFound from "../views/NotFound.vue";
import UserCustomerAdd from "../views/MobilityOnDemand/Users/UserCustomerAdd.vue";
import PassengersBookingsOverview from "../views/MobilityOnDemand/PassengersBookingsOverview.vue";
import PassengerRideDetails from "../views/MobilityOnDemand/PassengerRideDetails.vue";
import VouchersOverview from "../views/MobilityOnDemand/Vouchers/VouchersOverview.vue";
import VouchersDetails from "../views/MobilityOnDemand/Vouchers/VouchersDetails.vue";
import VouchersCreate from "../views/MobilityOnDemand/Vouchers/VouchersCreate.vue";
import AdvertisementsOverview from "@/views/DiePfalzBringts/Advertisements/AdvertisementsOverview.vue";
import AdvertisementsEditor from "@/views/DiePfalzBringts/Advertisements/AdvertisementsEditor.vue";
import WarehousingOverview from "@/views/DiePfalzBringts/Warehousing/WarehousingOverview.vue";
import TransactionsOverview from "../views/MobilityOnDemand/Transactions/TransactionsOverview.vue";
import TransactionDetails from "../views/MobilityOnDemand/Transactions/TransactionDetails.vue";
import VehiclesOverview from "../views/MobilityOnDemand/Vehicles/VehiclesOverview.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/Driver',
        name: 'Driver',
        component: Scanning
    },
    {
        path: '/Dashboard',
        name: 'Dashboard',
        component: Dashboard,
        children: [
            {
                path: 'PassengerBooking',
                name: 'PassengerBooking',
                component: PassengerBooking
            },
            {
                path: 'LogisticsBooking',
                name: 'LogisticsBooking',
                component: LogisticsBooking
            },
            {
                path: 'LogisticsBookingsOverview',
                name: 'LogisticsBookingsOverview',
                component: LogisticsBookingsOverview
            },
            {
                path: 'PassengersBookingsOverview',
                name: 'PassengersBookingsOverview',
                component: PassengersBookingsOverview
            },
            {
                path: 'PassengerRideDetails/:id',
                name: 'PassengerRideDetails',
                component: PassengerRideDetails
            },
            {
                path: 'RideDetails/:id',
                name: 'RideDetails',
                component: RideDetails
            },
            {
                path: 'MoDstops',
                name: 'MoDstops',
                component: MoDstops
            },
            {
                path: 'MoDstopDetails/:id',
                name: 'MoDstopDetails',
                component: MoDstopDetails
            },
            {
                path: 'Transactions',
                name: 'TransactionsOverview',
                component: TransactionsOverview
            },
            {
                path: 'Transactions/:id',
                name: 'TransactionDetails',
                component: TransactionDetails
            },
            {
                path: 'Vouchers',
                name: 'VouchersOverview',
                component: VouchersOverview
            },
            {
                path: 'Vouchers/:id',
                name: 'VouchersDetails',
                component: VouchersDetails
            },
            {
                path: 'Vouchers/create',
                name: 'VouchersCreate',
                component: VouchersCreate
            },
            {
                path: 'SettingsMoD',
                name: 'SettingsMoD',
                component: SettingsMoD
            },
            {
                path: 'Users',
                name: 'Users',
                component: Users
            },
            {
                path: 'UserDetails/:id',
                name: 'UserDetails',
                component: UserDetails
            },
            {
                path: 'UserAdd',
                name: 'UserAdd',
                component: UserAdd
            },
            {
                path: 'UserCustomerAdd',
                name: 'UserCustomerAdd',
                component: UserCustomerAdd
            },
            {
                path: 'ServiceHours',
                name: 'ServiceHours',
                component: ServiceHours
            },
            {
                path: 'Storefront',
                name: 'Storefront',
                component: Storefront
            },
            {
                path: 'VendorOverview',
                name: 'VendorOverview',
                component: VendorOverview
            },
            {
                path: 'VendorDetails/:id',
                name: 'VendorDetails',
                component: VendorDetails
            },
            {
                path: 'VendorProducts/:id',
                name: 'VendorProducts',
                component: VendorProducts
            },
            {
                path: 'VendorAdd/',
                name: 'VendorAdd',
                component: VendorAdd
            },
            {
                path: 'ProductOverview',
                name: 'ProductOverview',
                component: ProductOverview
            },
            {
                path: 'ProductDetails/:id',
                name: 'ProductDetails',
                component: ProductDetails
            },
            {
                path: 'ProductAdd',
                name: 'ProductAdd',
                component: ProductAdd
            },
            {
                path: 'CategoryOverview',
                name: 'CategoryOverview',
                component: CategoryOverview
            },
            {
                path: 'CategoryDetails/:id',
                name: 'CategoryDetails',
                component: CategoryDetails
            },
            {
                path: 'CategoryAdd',
                name: 'CategoryAdd',
                component: CategoryAdd
            },
            {
                path: 'Orders',
                name: 'Orders',
                component: Orders
            },
            {
                path: 'AdvertisementsOverview',
                name: 'AdvertisementsOverview',
                component: AdvertisementsOverview
            },
            {
                path: 'AdvertisementsEditor/:id',
                name: 'AdvertisementsEditor',
                component: AdvertisementsEditor
            },
            {
                path: 'WarehousingOverview',
                name: 'WarehousingOverview',
                component: WarehousingOverview
            },
            {
                path: 'OrderDetails/:id',
                name: 'OrderDetails',
                component: OrderDetails
            },
            {
                path: 'ProductList/:date',
                name: 'ProductList',
                component: ProductList
            },
            {
                path: 'SettingsDPB',
                name: 'SettingsDPB',
                component: SettingsDPB
            },
            {
                path: 'Vehicles',
                name: 'Vehicles',
                component: VehiclesOverview
            },
        ]
    },
    {
        path: '*',
        component: NotFound
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})


router.beforeEach((to, from, next) => {

    let accessToken = localStorage.getItem('accessToken')
    let payload = accessTokenPayload(accessToken)
    let isAuthenticated = isAccessTokenValid(payload)

    if (isAuthenticated) {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData) {
            store.dispatch("setUserdata", userData);
        } else {
            store.dispatch("setUserdata", payload);
        }
    }

    if (to.name !== 'Login' && to.name !== 'Driver' && !isAuthenticated) next({ name: 'Login' })
    else next()
})

export default router;

