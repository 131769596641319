




























































































































































































































import timeslotComponent from "@/components/TimeslotComponent.vue";
import DPBUpload from "@/components/DPBUpload.vue";
import {
  processStringToMoney,
  processMoneyToString,
} from "@/lib/processMoneyString";
import { ChevronLeftIcon, SaveIcon } from "vue-feather-icons";
import router from "../../../router";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import store from "@/store";

export default Vue.extend({
  name: "Home",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    DPBUpload,
    timeslotComponent,
  },

  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      vendor: null as any,
      extendendAddress: false,
      deliveryFee: null as any,
      vendorDeliveryFactor: null as any,
      MBW: null as any,
      LogoLink: null as any,
      LogoId: undefined as any,
      HeaderLink: null as any,
      HeaderId: undefined as any,
      deliveryTimeslots: [] as any[],
      error: null,
      role: store.state.role,
    };
  },

  methods: {
    save: function () {
      //console.log(this.vendor.addresses);
      console.log("VENDOR", this.vendor);
      // Get new timeslots
      const newTimeslots = this.deliveryTimeslots.filter((el) => el.id == null);

      // make valid url
      if (this.vendor.webUrl && this.vendor.webUrl !== '' && !this.vendor.webUrl.startsWith("http://") && !this.vendor.webUrl.startsWith("https://")) {
        const prefix = "http://";
        this.vendor.webUrl = prefix.concat(this.vendor.webUrl);
      }

      newTimeslots.forEach(async (slot) => {
        console.log("New Timeslot");
        await fetchApi(
          `v1/vendors/${this.id}/delivery-time-slots`,
          "POST",
          slot
        ).catch((e) => {
          console.error("ERR TIMESLOT", e);
          this.error = e;
          return;
        });
      });

      this.vendor.shortDescription;

      // DELETE Timeslots
      this.vendor.defaultDeliveryTimeSlots.forEach(
        async (slot: { id: any }, index: any) => {
          let deleted = true;

          this.deliveryTimeslots.forEach((delSlot) => {
            if (slot.id === delSlot.id) {
              deleted = false;
            }
          });

          if (deleted) {
            await fetchApi(
              `v1/vendors/${this.id}/delivery-time-slots/${slot.id}`,
              "DELETE"
            ).catch((e) => {
              console.error("ERR DELETED", e);
              this.error = e;
              return;
            });
          }
        }
      );

      this.vendor.deliveryFee = processMoneyToString(this.deliveryFee);
      this.vendor.minOrderValue = processMoneyToString(this.MBW);

      const address1 = this.vendor.addresses[0];
      let address1ID = address1.id;
      let address2;

      if (this.extendendAddress) {
        address2 = { ...this.vendor.addresses[1] };
      } else {
        address2 = { ...this.vendor.addresses[0] };
      }
      let address2ID = this.vendor.addresses[1].id;

      delete address2.type;
      delete address2.geocodedLocation;
      delete address2.id;

      delete address1.type;
      delete address1.geocodedLocation;
      delete address1.id;

      delete this.vendor.addresses;
      delete this.vendor.id;
      delete this.vendor.createdAt;
      delete this.vendor.updatedAt;
      delete this.vendor.users;
      delete this.vendor.categories;
      delete this.vendor.defaultDeliveryTimeSlots;
      delete this.vendor.deletedAt;

      delete this.vendor.afDemandSourceIdBookingForm;
      delete this.vendor.afDemandSourceIdOrdering;

      this.vendor.deliveryFeeFactor = parseFloat(this.vendor.deliveryFeeFactor);

      if (this.LogoId === undefined) {
        delete this.vendor.logoImageUri;
      } else {
        this.vendor.logoImageUri = this.LogoId;
      }

      if (this.HeaderId === undefined) {
        delete this.vendor.headerImageUri;
      } else {
        this.vendor.headerImageUri = this.HeaderId;
      }

      fetchApi(
        "v1/vendors/" + this.id + "/addresses/" + address1ID,
        "PATCH",
        address1
      )
        .then((data) => {
          console.log(data);
        })
        .catch((e) => {
          console.error("ERR ADR1", e);
          this.error = e;
          return;
        });

      fetchApi(
        "v1/vendors/" + this.id + "/addresses/" + address2ID,
        "PATCH",
        address2
      )
        .then((data) => {
          console.log(data);
        })
        .catch((e) => {
          console.error("ERR ADR2", e);
          this.error = e;
          return;
        });

      fetchApi("v1/vendors/" + this.id, "PATCH", this.vendor)
        .then((data) => {
          console.log("SAVE DATA", data);
          alert("Vendor erfolgreich gespeichert.");
          if (this.role === "ADMIN") {
            router.push({ name: "VendorOverview" });
          } else {
            router.push({ name: 'VendorDetails', params: { is: this.vendor.id } })
          }
        })
        .catch((e) => {
          console.error("ERR VENDOR", e);
          alert("Speichern des Vendors fehlgeschlagen.");
          this.error = e;
          return;
        });
    },

    getVendor: async function () {
      await fetchApi("v1/vendors/" + this.id, "GET", null)
        .then(async (data) => {
          if (data != null) {
            this.vendor = data;
            this.deliveryFee = processStringToMoney(
              await this.vendor.deliveryFee
            );
            this.MBW = processStringToMoney(await this.vendor.minOrderValue);
            console.log("DATA", this.vendor);

            // Avoid Image caching by adding random number
            if (this.vendor.logoImageUri != null) {
              this.LogoLink = this.vendor.logoImageUri + "?" + Math.random();
            }
            if (this.vendor.headerImageUri != null) {
              this.HeaderLink =
                this.vendor.headerImageUri + "?" + Math.random();
            }

            this.vendor["deliveryTimeSlots"] = [];
          } else {
            throw data["message"];
          }
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });

      fetchApi("v1/vendors/" + this.id + "/delivery-time-slots", "GET", null)
        .then(async (data) => {
          this.deliveryTimeslots = data;
        })
        .catch((e) => {
          console.error("ERR", e);
          this.error = e;
        });

      this.loading = false;
    },

    onLogoId(fileId: string) {
      console.log(fileId);
      this.LogoId = fileId;
    },
    onHeaderId(fileId: string) {
      console.log(fileId);
      this.HeaderId = fileId;
    },
    deleteVendor: function (continueDelete) {
      if (continueDelete) {
        fetchApi(`v1/vendors/${this.id}`, "DELETE")
          .then(() => {
            router.push({ name: "VendorOverview" });
          })
          .catch((e) => {
            this.error = e;
          });
      } else {
        this.$bvModal.show("confirmDelete-DialogVendor");
      }
    },
  },

  async created() {
    await this.getVendor();
  },
});
