














































































































































































































































































































































































import { ChevronLeftIcon, SaveIcon, ArrowRightIcon } from "vue-feather-icons";
import { fetchApi } from "@/lib/fetch";
import Vue from "vue";
import VueSelect from "vue-select";
import router from "@/router";
import dayjs from "dayjs";
import { getBadgeClassForState } from "@/lib/uiStatesUtil";
import { makeStateString } from "@/lib/makeStateString";

export default Vue.extend({
  name: "PassengerRideDetails",
  components: {
    ChevronLeftIcon,
    SaveIcon,
    VueSelect,
    ArrowRightIcon,
  },

  data() {
    return {
      makeStateString,
      getBadgeClassForState,
      id: this.$route.params.id,
      ride: {},
      loading: true,
      error: null,
      modalShow: false,
      selectedState: "",
      failureReason: "",
      cancellationReason: "",
    };
  },
  methods: {
    async changeState() {
      let body: any = {
        state: this.selectedState,
      };
      if (this.selectedState === "CANCELLED") {
        body.reason = this.cancellationReason;
      } else if (this.selectedState === "FAILED") {
        body.reason = this.failureReason;
      }
      try {
        const res = await fetchApi(
          `v1/passenger-rides/${this.id}/change-state`,
          "POST",
          body
        );
        this.modalShow = false;
      } catch (error) {
        console.log(error);
        alert("Fehler bei der Änderung.");
      }
    },
    getPassengerRide: async function () {
      await fetchApi("v1/passenger-rides/" + this.id, "GET", null).then(
        async (data) => {
          if (data != null) {
            console.log(data);
            this.ride = data;
          }
        }
      );
      this.loading = false;
    },
    formatDate(dateAsString: string, longMonth = false) {
      if (longMonth) {
        return dayjs(dateAsString).format("DD. MMMM YYYY [um] HH:mm:ss");
      }
      return dayjs(dateAsString).format("DD.MM.YYYY HH:mm:ss");
    },
    goToUser(id: string) {
      window.open(`/Dashboard/UserDetails/${id}`);
    },
    goToAF(id: string) {
      window.open(
        `https://control.autofleet.io/i2rFSquL5gtNZupkKD5VWz/ride/${id}`
      );
    },
  },
  async created() {
    await this.getPassengerRide();
  },
  computed: {
    possibleStates() {
      return [
        { value: "CREATING", text: "CREATING", disabled: true },
        { value: "PENDING", text: "PENDING", disabled: true },
        { value: "DISPATCHED", text: "DISPATCHED", disabled: true },
        { value: "ACTIVE", text: "ACTIVE", disabled: true },
        { value: "FAILED", text: "FAILED" },
        { value: "REJECTED", text: "REJECTED", disabled: true },
        { value: "NO_SHOW", text: "NO_SHOW" },
        { value: "CANCELLED", text: "CANCELLED" },
        { value: "COMPLETED", text: "COMPLETED" },
      ];
    },
  },
});
